<template>
  <v-dialog v-model="show" :retain-focus="false" max-width="1000">
    <v-card>
       <v-card-title class="justify-center">
          <span class="text-h5"><strong> PERMISSION</strong></span>
        </v-card-title>
         <v-row class="mx-3 pt-3 justify-center d-flex">
          <v-col cols="12" md="4" >
              <h4 >FORM</h4>
            </v-col>
            <v-col cols="12" md="4" >
              <h4> Action</h4>
            </v-col>
        </v-row>
        <template v-for="Obj in this.permissions">
        <v-row class="mx-3 pt-3 justify-center d-flex">
          <v-col cols="12" md="3">
              <p>{{((Obj.form_name).replace(/_/g, " ")).toUpperCase()}} :</p>
            </v-col>
            <v-col cols="12" md="3" class="pt-2">
               <v-checkbox label="View" value="1" v-model="Obj.view" hide-details class="mt-0 pt-0"></v-checkbox>
            </v-col>
            <v-col cols="12" md="3" class="pt-2">
              
               <v-checkbox label="Edit" value="1" v-model="Obj.edit" hide-details class="mt-0 pt-0"></v-checkbox>
            </v-col>
        </v-row>
        </template>
      <v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="green darken-1" text @click="show = false"> Cancel </v-btn>
				<v-btn color="primary" @click="permissionData()"> Save </v-btn>
			</v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
	</v-snackbar>
  </v-dialog>
  
</template>


<script>

import roleService from '@/service/role.service'

export default {
  data(){
    return{
      notificationview:'',
      notificationedit:'',
      permissions:[],
      permission_arr:["Notification Form","Form A","Form B","Form C","Donor information with consent","Eye donor medical particulars","hemodilution assessment",
      "Tissue evaluation","Tissue detail form","Tissue offered","Bill of lading","Adverse reaction report","Recipient information form","Tissue disposition",],
      permissionss:[
          {"form_name":"notification_form","view":"0","edit":"0" ,"name":"Notification Form"},
          {"form_name":"form_a","view":"0","edit":"0","name":"Form A"},
          {"form_name":"form_b","view":"0","edit":"0","name":"Form B"},
          {"form_name":"form_c","view":"0","edit":"0","name":"Form C - Laboratory Information"},
          {"form_name":"donor_information_with_consent","view":"0","edit":"0","name":"Donor information with consent"},
          {"form_name":"eye_donor_medical_particulars","view":"0","edit":"0","name":"Donor medical particulars"},
          {"form_name":"form_c","view":"0","edit":"0","name":"Form C"},
          {"form_name":"hemodilution_assessment","view":"0","edit":"0","name":"Hemodilution assessment"},
          {"form_name":"tissue_evaluation","view":"0","edit":"0","name":"Tissue evaluation"},
          {"form_name":"tissue_detail_form","view":"0","edit":"0","name":"Tissue detail form"},
          {"form_name":"tissue_offered","view":"0","edit":"0","name":"Tissue offered"},
          {"form_name":"bill_of_lading","view":"0","edit":"0","name":"Bill of lading"},
          {"form_name":"adverse_reaction_report","view":"0","edit":"0","name":"Adverse reaction report"},
          {"form_name":"recipient_information_form","view":"0","edit":"0","name":"Recipient information form"},
          {"form_name":"tissue_disposition","view":"0","edit":"0","name":"Tissue disposition"},
          
      ],
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
    }
  },
  props: ['visible', 'item', 'permissions'],
  computed: {
    show: {
      get() {
        return this.visible
        
      },
      set(value) {
        if (!value) {
          this.$emit('close') 
         
        }
      },
    },
  },
  methods: {
    async permissionData() {
      try {
        this.show = false
        const data = {
          role_id: this.item.id,
          permissions:(this.permissions),
        }
        const service = new roleService()
        const response = await service.permission(data)
        if (response.status == 200) {
					this.snackbarText = response.message
          this.snackbar = true
				}else {
					this.snackbarText = response.message
          this.snackbar = true
				}
				this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
