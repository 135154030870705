var axios = require("axios").default;
const baseUrl = `https://api.stage.ebsr.in/users`;
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
export default class Role {
    async roleList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}/role_list`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data 
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async updateRoleUser(data) {
        try {
            const response = await axios.put(`${baseUrl}/edit_role`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };
    async permission(data) {
        try {
            const response = await axios.post(`${baseUrl}/assige_role_permissions`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };
    async getrole(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}/get_permissions_by_id/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
}
