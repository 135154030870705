<template>
  <v-dialog v-model="show" :retain-focus="false" max-width="550">
    <v-card>
      <v-card-title class="text-h5 mb-3" max-width="500"> Edit Role Name </v-card-title>
	  <v-text-field outlined v-model="item.role" dense class="ml-5 mr-5 mb-8" placeholder="Enter role name"></v-text-field>
      <v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="green darken-1" text @click="show = false"> Cancel </v-btn>
				<v-btn color="primary" @click="editRole()"> Save </v-btn>
			</v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
	</v-snackbar>
  </v-dialog>
  
</template>


<script>

import roleService from '@/service/role.service'

export default {
  data(){
    return{
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
    }
  },
  props: ['visible', 'item'],
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
          setTimeout(() => {
            location.reload()
          }, 100)
        }
      },
    },
  },
  methods: {
    async editRole() {
      try {
        this.show = false
        const data = {
          role: this.item.role,
          role_id: this.item.id,
        }
        const service = new roleService()
        const response = await service.updateRoleUser(data)
        //console.log("shubham", response)
        if (response.status == 200) {
					this.snackbarText = response.message
				}else {
					this.snackbarText = response.message
				}
				this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
